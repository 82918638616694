import Vue from 'vue';
import Dialog from './dialog.vue';

let instance;
function createInstance() {
  const DialogConstructor = Vue.extend(Dialog);
  instance = new DialogConstructor();
  instance.$mount(document.createElement('div'));
  document.body.appendChild(instance.$el);
}

const NDialog = {
  confirm(options) {
    if (!instance) {
      createInstance();
    }
    const promise = new Promise((resolve) => {
      instance.resolve = resolve;
    });
    const {
      title,
      message,
      okText,
      cancelText,
      showClose,
      isLoding,
      isHideCancel,
    } = options;
    instance.title = title;
    instance.message = message;
    instance.show = true;
    instance.okText = okText;
    instance.cancelText = cancelText;
    instance.showClose = showClose;
    instance.isLoding = isLoding;
    instance.isHideCancel = isHideCancel;
    return promise;
  },
  close() {
    instance.isLoding = false;
    instance.loading = false;
    instance.show = false;
  },
};

export default NDialog;
