<template>
  <div class="box-faultr" id="contentBox" ref="contentBox">
    <div class="header">
      <img
        @click="goback"
        class="back"
        src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/back.png"
        alt=""
      />
      <div class="title">故障上报</div>
    </div>
    <!-- 车辆 -->
    <div class="top-vin">
      <div class="text">
        车辆 <span class="vin">{{ vinId }}</span>
      </div>
      <div class="location">
        <img src="../../assets/faultrReporting/location.png" alt="" />
        <span>{{ parkName }}</span>
      </div>
    </div>
    <div class="line"></div>
    <!-- 表单 -->
    <div class="content">
      <van-form>
        <!-- 故障时间 -->
        <div class="time-picker">
          <van-field
            readonly
            clickable
            name="dateTime"
            :value="form.timeValue"
            label="故障时间"
            placeholder="请选择故障时间"
            @click="timePickerShow = true"
            :rules="[{ required: true }]"
          />
          <timePicker
            :values="form.timeValue"
            ref="popup"
            :showPicker="timePickerShow"
            @confirm="confirmOn"
            @cancel="timePickerShow = false"
          />
        </div>
        <!-- 故障类型 -->
        <div>
          <van-field
            readonly
            clickable
            name="moduleType"
            v-model="form.moduleType"
            label="故障类型"
            placeholder="请选择故障类型"
            @click-input="showPickerType = true"
            :rules="[{ required: true }]"
          />
          <van-popup v-model="showPickerType" position="bottom">
            <van-picker
              value-key="name"
              show-toolbar
              :columns="typeColumns"
              @confirm="onConfirmType"
              @cancel="showPickerType = false"
              title="选择故障类型"
            />
          </van-popup>
        </div>
        <div v-if="this.typeValueID">
          <!-- 故障模块 -->
          <div v-if="!['SD', 'HS', 'CE', 'HS_E'].includes(this.typeValueID)">
            <van-field
              readonly
              clickable
              name="moduleId"
              v-model="form.moduleValue"
              label="故障模块"
              placeholder="请选择故障模块"
              @click-input="showPickerModule = true"
              :rules="[{ required: true }]"
            />
            <van-popup v-model="showPickerModule" position="bottom">
              <van-picker
                value-key="name"
                show-toolbar
                :columns="moduleColumns"
                @confirm="onConfirmModule"
                @cancel="showPickerModule = false"
                title="选择故障模块"
                :default-index="this.typeValueID === 'AD' ? '1' : ''"
              />
            </van-popup>
          </div>
          <!-- 主故障 -->
          <div :class="mainfaultDisabled ? 'child-picker-dis' : ''">
            <van-field
              readonly
              clickable
              name="mainQuestionId"
              v-model="form.mainfaultValue"
              label="主故障"
              placeholder="请选择主故障"
              @click-input="showPickerMainfault = true"
              :rules="[{ required: true }]"
              :disabled="mainfaultDisabled"
            />
            <van-popup v-model="showPickerMainfault" position="bottom">
              <van-picker
                value-key="name"
                show-toolbar
                :columns="mainfaultColumns"
                @confirm="onConfirmMainfault"
                @cancel="showPickerMainfault = false"
                title="选择主故障"
              />
            </van-popup>
          </div>
          <!-- 子故障 -->
          <div
            v-if="['AD'].includes(this.typeValueID)"
            :class="childfaultDisabled ? 'child-picker-dis' : ''"
          >
            <van-field
              readonly
              clickable
              name="subQuestionId"
              v-model="form.childfaultValue"
              label="子故障"
              placeholder="请选择子故障"
              @click-input="showPickerChildfault = true"
              :rules="[{ required: true }]"
              :disabled="childfaultDisabled"
            />
            <van-popup v-model="showPickerChildfault" position="bottom">
              <van-picker
                value-key="name"
                show-toolbar
                :columns="childfaultColumns"
                @confirm="onConfirmChildfault"
                @cancel="showPickerChildfault = false"
                title="选择子故障"
              />
            </van-popup>
          </div>
          <!-- 具体描述 -->
          <div class="textDes">
            <van-field
              name="remark"
              v-model="form.message"
              rows="3"
              autosize
              label="具体描述"
              type="textarea"
              maxlength="500"
              placeholder="可更具体地描述故障，最多500字"
              @focus="handleInputFocus"
              @blur="handleInputBlur"
            />
          </div>
          <!-- 上传/拍摄图片 -->
          <div class="upload">
            <div class="top" :class="{ required: businessType === 'EXTERNAL' }">
              <span>故障图片/视频</span>
              <span>(最多6张)</span>
            </div>
            <div class="pic">
              <van-uploader
                v-model="fileList"
                :after-read="onAfterRead"
                :before-read="onBeforeRead"
                :before-delete="delate"
                ref="uploader"
                :max-count="6"
                class="pic-upload"
              >
                <!-- :accept="acceptType" -->
                <div v-if="fileList.length < 6" class="uploadTrigger">
                  <img
                    class="needsclick"
                    @click="handleUploadClick"
                    src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/Frontend/zsd/uploader.png"
                    alt=""
                  />
                </div>
              </van-uploader>
            </div>
          </div>
        </div>
      </van-form>

      <!-- 【具体描述】弹出键盘有遮盖，用来占位高度，防止键盘遮盖  -->
      <!-- <div class="btn">
        <van-button class="btn-fault" round type="info" @click="debugInLocal"
          >上报ceshi故障</van-button
        >
      </div> -->
    </div>
    <!-- 上报故障按钮 -->
    <div class="bottomBtn submitBtn" v-show="hideshow">
      <div v-if="submitDisabled" class="disabled">上报故障</div>
      <div v-else @click="handleFaultReport">上报故障</div>
    </div>
  </div>
</template>
<script>
import fetchRequest from '@/utils/fetchRequest';
import NDialog from '@/components/NDialog';
import { requestCameraPermission } from '@/utils';
import moment from 'moment';
import { Toast } from 'vant';
import TimePicker from '@/components/TimePicker.vue';
import { getVehicleMissionSubway } from '@/api/api';

export default {
  name: 'FaultReporting',

  components: {
    TimePicker,
  },
  data() {
    return {
      // 模块故障
      moduleValueID: '',
      moduleColumns: [],
      showPickerModule: false,
      // 主故障
      mainfaultValueID: '',
      mainfaultColumns: [],
      showPickerMainfault: false,
      // 子故障
      childfaultValueID: '',
      childfaultColumns: [],
      showPickerChildfault: false,

      childfaultDisabled: true, //子故障禁用
      mainfaultDisabled: true, // 主故障禁用
      fileList: [], // 上传文件
      fileListNewArr: [],
      form: {
        timeValue: moment().format('YYYY-MM-DD HH:mm:ss'), //修改后的时间
        moduleType: '',
        moduleValue: '',
        mainfaultValue: '',
        childfaultValue: '',
        message: '', //具体描述信息
      },
      // 原始值
      formOriginal: {
        timeValue: moment().format('YYYY-MM-DD HH:mm:ss'), //修改后的时间
        moduleType: '',
        moduleValue: '',
        mainfaultValue: '',
        childfaultValue: '',
        message: '', //具体描述信息
      },
      changed: false, // 是否修改过信息
      btnDisabled: true, // 上报故障按钮
      vinId: '',
      vin: '',
      parkName: '',
      fileArr: [],
      mainfaultArrAll: [], //主故障全部数据
      // 故障时间
      timePickerShow: false, //打开/关闭时间选择器
      timeData: moment().format('YYYY-MM-DD HH:mm:ss'), // 进页面的时间

      docmHeight: document.documentElement.clientHeight, //默认屏幕高度
      showHeight: document.documentElement.clientHeight, //实时屏幕高度
      hideshow: true,

      // 故障类型
      showPickerType: false,
      typeValueID: '',
      typeColumns: [],
      moduleArrAll: [], //故障模块全部数据
      acceptType: '',
      subWay: null, // 线路接口
      businessType: 'INTERNAL',
    };
  },

  computed: {
    submitDisabled() {
      return 'HS_E' === this.typeValueID
        ? this.btnDisabled || this.fileList.length === 0
        : this.btnDisabled;
    },
  },

  watch: {
    // 当输入框被遮挡时隐藏底部按钮
    showHeight: function () {
      // 奇葩问题
      if (this.docmHeight > this.showHeight + 3) {
        this.hideshow = false;
      } else {
        this.hideshow = true;
      }
    },
    form: {
      handler(newValue) {
        // 判断【上报故障】按钮禁用状态 moduleType
        if (
          ['SD', 'HS', 'CE', 'HS_E'].includes(this.typeValueID) &&
          newValue.moduleType !== '' &&
          newValue.mainfaultValue !== '' &&
          newValue.message.length !== 0
        ) {
          this.btnDisabled = false;
        } else if (
          this.typeValueID === 'AD' &&
          newValue.moduleValue !== '' &&
          newValue.mainfaultValue !== '' &&
          newValue.childfaultValue !== '' &&
          newValue.message.length !== 0
        ) {
          this.btnDisabled = false;
        } else if (
          ['OT', 'VA'].includes(this.typeValueID) &&
          newValue.moduleType !== '' &&
          newValue.moduleValue !== '' &&
          newValue.mainfaultValue !== '' &&
          newValue.message.length !== 0
        ) {
          this.btnDisabled = false;
        } else {
          this.btnDisabled = true;
        }
      },
      deep: true,
    },
  },

  mounted: function () {
    this.vinId = this.$route.query.vinId;
    this.vin = this.$route.query.vin;
    this.parkName = this.$route.query.parkName;
    this.businessType = this.$route.query.businessType || 'INTERNAL'; // 业务类型 默认内部 INTERNAL 外部 EXTERNAL
    this.getVehicleSubway();
    this.getModuleType();
    // this.acceptHandle()

    if (this.businessType === 'EXTERNAL') {
      this.onConfirmType({ name: '外观问题', id: 'HS_E' });
    }

    window.cameraPermission = () => {
      this.clicked = true;
      const uploaderIpt = this.$refs['uploader'].$el.querySelector('input[type=file]');
      uploaderIpt.click();
    };

    window.addEventListener('resize', () => {
      this.showHeight = document.body.clientHeight;
    });
  },

  created: function () {
    //这里定义窗口的原始高度
    this.oldHeight = document.documentElement.clientHeight;
  },

  beforeDestroy() {
    window.cameraPermission = null;
  },

  methods: {
    confirmOn(val) {
      if (moment(this.timeData).diff(moment(val)) < 0) {
        return Toast({
          message: '不可超过当前时间',
          className: 'response-toast',
          forbidClick: true,
        });
      }
      this.timePickerShow = false;
      this.form.timeValue = val;
    },

    // 获取路线
    getVehicleSubway() {
      getVehicleMissionSubway(this.vin).then((data) => {
        this.subWay = data;
      });
    },

    // 模块类型
    getModuleType() {
      fetchRequest('faultReport/type/list', {
        method: 'GET',
        data: {
          version: this.businessType,
        },
      }).then((data) => {
        data.map((item) => {
          this.typeColumns = item.children;
        });
      });
    },

    // 模块列表
    getModuleList(type) {
      fetchRequest('faultReport/module/list', {
        method: 'GET',
        data: {
          type: 'GC',
          subType: type,
          version: this.businessType,
        },
      }).then((data) => {
        if (this.typeValueID === 'AD') {
          this.form.moduleValue = data[1].name;
          this.formOriginal.moduleValue = data[1].name;
        }
        let moduleArr = [];
        data.map((item) => {
          moduleArr.push({
            id: item.id,
            name: item.name,
          });
        });
        this.moduleColumns = moduleArr;
        this.moduleArrAll = data;
      });
    },

    // 主故障列表
    getQuestionList(subType) {
      fetchRequest('faultReport/question/list', {
        method: 'GET',
        data: {
          type: 'GC',
          subType,
          version: this.businessType,
        },
      }).then((data) => {
        let mainfaultArr = [];

        data.map((item) => {
          mainfaultArr.push({
            id: item.id,
            name: item.name,
            types: item.types,
          });
        });
        this.mainfaultColumns = mainfaultArr;
        this.mainfaultArrAll = data;
        if (this.businessType === 'EXTERNAL') {
          this.onConfirmMainfault({ name: data[0].name, id: data[0].id });
        }
      });
    },

    // 返回
    async goback() {
      // 判断数据是否一致
      if (JSON.stringify(this.form) === JSON.stringify(this.formOriginal)) {
        this.changed = false;
      } else {
        this.changed = true;
      }
      if (this.changed) {
        const result = await NDialog.confirm({
          message: '内容未保存，是否确定退出？',
        });
        if (result === 'confirm') {
          window.location.href = 'neolix://interaction';
        }
      } else {
        window.location.href = 'neolix://interaction';
      }
    },

    // 点击上报故障
    async handleFaultReport() {
      const result = await NDialog.confirm({
        message: '确定上报该故障吗？',
        isLoding: true,
      });
      if (result === 'confirm') {
        let params = {
          carId: this.vinId,
          dateTime: this.form.timeValue,
          reporter: this.$route.query.realName,
          type: 'GC', // 主类型
          subType: this.typeValueID, // 子类型
          moduleId: ['SD', 'HS', 'CE', 'HS_E'].includes(this.typeValueID)
            ? ''
            : this.moduleValueID
            ? this.moduleValueID
            : this.moduleColumns[1].id, // 模块ID
          mainQuestionId: this.mainfaultValueID, // 主异常ID
          subQuestionId: this.typeValueID === 'AD' ? this.childfaultValueID : '', // 子异常ID
          remark: this.form.message,
          filenames: this.fileArr,
          device: 'ZSD', // 来源设备
          startStation: this.subWay.departureStationName
            ? this.subWay.departureStationName
            : '未匹配', // 起始站点
          endStation: this.subWay.destinationStationName
            ? this.subWay.destinationStationName
            : '未匹配', // 终点站点
          grid: this.subWay.parkName, //  车辆所属网格
          routeName: this.subWay.subwayName || '', // 线路名称
        };
        // console.log(params, "..scs");

        fetchRequest('faultReport/create', {
          method: 'POST',
          data: params,
        })
          .then(() => {
            Toast({
              message: '上报成功',
              className: 'response-toast',
              forbidClick: true,
            });
            NDialog.close();
            setTimeout(() => {
              window.location.href = 'neolix://interaction';
            }, 2000);
          })
          .catch(() => {
            NDialog.close();
          });
      }
    },

    // 故障类型
    onConfirmType(value) {
      this.form.moduleType = value.name;
      this.typeValueID = value.id;
      this.showPickerType = false;
      this.form.moduleValue = '';
      this.form.mainfaultValue = '';
      this.form.childfaultValue = '';
      this.form.message = '';

      if (value.id === 'AD') {
        this.getQuestionList(value.id); // 主、子故障
        this.getModuleList(value.id); // 模块
        this.mainfaultDisabled = false;
        return;
      }

      if (['SD', 'HS', 'CE', 'HS_E'].includes(value.id)) {
        this.getQuestionList(value.id); // 主、子故障
        this.mainfaultDisabled = false;
      } else {
        this.getModuleList(value.id); // 模块
        this.mainfaultDisabled = true;
      }
    },

    // 模块故障
    onConfirmModule(value) {
      this.form.moduleValue = value.name;
      this.moduleValueID = value.id;
      this.showPickerModule = false;
      this.form.mainfaultValue = '';
      this.form.childfaultValue = '';

      if (!['SD', 'HS', 'CE', 'AD', 'HS_E'].includes(this.typeValueID)) {
        this.mainfaultDisabled = false;
        let childfaultArr = [];
        this.moduleArrAll.map((item) => {
          item.questions.map((items) => {
            if (item.name === value.name) {
              childfaultArr.push({
                id: items.id,
                name: items.name,
              });
            }
          });
        });
        this.mainfaultColumns = childfaultArr;
      }
    },

    // 主故障
    onConfirmMainfault(value) {
      this.form.mainfaultValue = value.name;
      this.mainfaultValueID = value.id;
      this.showPickerMainfault = false;

      if (this.typeValueID === 'AD') {
        this.childfaultDisabled = false;
        let childfaultArr = [];
        this.mainfaultArrAll.map((item) => {
          item.children.map((items) => {
            if (item.name === value.name) {
              childfaultArr.push({
                id: items.id,
                name: items.name,
                types: items.types,
              });
            }
          });
        });
        this.childfaultColumns = childfaultArr;
        this.form.childfaultValue = '';
      }
    },

    // 子故障
    onConfirmChildfault(value) {
      this.form.childfaultValue = value.name;
      this.childfaultValueID = value.id;
      this.showPickerChildfault = false;
    },

    onAfterRead(file) {
      fetchRequest('obs/upload/token', {
        method: 'GET',
        data: {
          uploadType: 'FAULT_REPORT_FORM',
        },
      }).then((data) => {
        let number = this.generateRandomHex(16);
        const formData = new FormData();
        const reNamedFile = new File([file.file], number + file.file.name, {
          type: file.file.type,
        });

        // 兼容三个云 https://r3c0qt6yjw.feishu.cn/wiki/GowBwcc1uiuvLmkthogcbPX3nQb
        switch (data.type) {
          case 'ZOS':
            formData.append('key', data.uploadDir + number + file.file.name);
            // formData.append('content-type', 'text/plain');
            formData.append('Policy', data.policy);
            formData.append('AWSAccessKeyId', data.accessKeyId);
            formData.append('signature', data.signature);
            break;
          case 'EOS':
            formData.append('key', data.uploadDir + number + file.file.name);
            // formData.append('content-type', 'text/plain');
            formData.append('Policy', data.policy);
            // formData.append('AWSAccessKeyId', data.accessKeyId);
            formData.append('X-Amz-Signature', data.signature);
            formData.append('X-Amz-Algorithm', data.amzAlgorithm);
            formData.append('X-Amz-Credential', data.amzCredential);
            formData.append('X-Amz-Date', data.amzDate);
            break;
          default:
            formData.append('key', data.uploadDir + number + file.file.name);
            formData.append('content-type', 'text/plain');
            formData.append('policy', data.policy);
            formData.append('AccessKeyId', data.accessKeyId);
            formData.append('signature', data.signature);
        }
        formData.append('file', reNamedFile);

        fetchRequest(data.action.replace('http:', 'https:'), {
          method: 'POST',
          data: formData,
          isUrl: false,
        }).then(() => {
          // console.log(data);
        });
        this.fileArr.push(number + file.file.name);
      });
    },

    onBeforeRead(file) {
      let status = true;
      if (!file.type.includes('video/') && !file.type.includes('image/')) {
        Toast({
          message: '请上传图片、视频',
          className: 'response-toast',
          forbidClick: true,
        });
        status = false;
        return false;
      }
      if (file.size > 10 * 1000 * 1024) {
        Toast({
          message: `请上传10MB以内的${file.type.includes('video/') ? '视频' : '图片'}`,
          className: 'response-toast',
          forbidClick: true,
        });
        status = false;
        return false;
      }
      return status;
    },
    generateRandomHex(length) {
      var result = '';
      var characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return result;
    },
    handleUploadClick() {
      if (process.env.NODE_ENV === 'development') {
        const uploaderIpt = this.$refs['uploader'].$el.querySelector('input[type=file]');
        uploaderIpt.click();
      }
      this.hideshow = true;
      requestCameraPermission();
    },

    debugInLocal() {
      location.href =
        // "http://192.168.1.6:8080/FaultReporting?vin=LHTAG2A27NY9PA007&vinId=X3P269&token=1bee7d34-e774-4d92-8bee-164a59a51701&userId=2004&realName=用户5782&parkName=北京恒通商务园&parkCode=010105&uuidWeb=c491a698-fbe3-4992-a852-a9bfb12bb6c3";
        'http://172.16.5.4:8080/FaultReporting?vin=LHTBW2B21RY2UA010';
    },

    delate(file, detail) {
      this.fileArr.splice(detail.index, 1);
      this.fileList.splice(detail.index, 1);
    },

    handleInputBlur() {
      setTimeout(() => {
        this.hideshow = true;
      }, 500);
    },

    handleInputFocus() {
      let u = navigator.userAgent;
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      // 区分iOS 和 安卓
      if (isIOS) {
        // iOS主要使用scrollTop，且需要加上延迟
        // document.getElementByClassName iOS容易获取不到dom
        setTimeout(() => {
          this.hideshow = false;
          this.$refs.contentBox.scrollTop = this.docmHeight / 2;
        }, 200);
      } else {
        this.hideshow = false;
        // scroll 在iOS的兼容性不好
        this.$refs.contentBox.scroll({
          top: 600,
          left: 0,
          behavior: 'smooth',
        });
      }
    },

    // 判断是iOS还是安卓限制相机显示，因为安卓会出现两个相机
    acceptHandle() {
      let u = navigator.userAgent;
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      if (isIOS) {
        // ios 显示视频录制也可上传视频, 防止下一个需求再次添加
        // this.acceptType = '*'
        this.acceptType = 'image/*';
      } else {
        // 安卓不显示录屏。只有拍照，但是可上传视频
        this.acceptType = 'image/*';
      }
    },
  },
};
</script>
<style>
body,
html {
  background-color: #fff !important;
}
</style>
<style scoped lang="scss">
#contentBox {
  height: 100%;
  overflow: scroll;
}

.box-faultr {
  width: 100%;
  // height: 100vh;
  font-size: initial;
  background: #fff;
  .header {
    height: 40px;
    line-height: 40px;
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    background: #ffffff;
    z-index: 100;
    .back {
      width: 32px;
      height: 32px;
      margin: 4px 11px;
    }
    .title {
      font-size: 15px;
      color: #000000;
      font-weight: 600;
      text-align: center;
      width: 100%;
      min-width: 100px;
      margin-right: 54px;
    }
  }
  .top-vin {
    width: 100%;
    padding: 16px 20px;
    background: #ffffff;
    margin-top: 40px;
    .text {
      font-size: 17px;
      color: #000000;
      margin-bottom: 6px;
      font-weight: 600;
      .vin {
        font-size: 20px;
      }
    }
    .location {
      margin-right: 2px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(151, 152, 171, 1);
      display: flex;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
  .line {
    width: 100%;
    height: 10px;
    background: rgba(246, 247, 248, 1);
  }
  .content {
    width: 100%;
    // margin-top: 10px;
    padding-bottom: 94px;
    .time-picker {
      ::v-deep .van-cell {
        input {
          background-image: url('../../assets/faultrReporting/time.png');
        }
      }
    }
    .child-picker-dis {
      ::v-deep .van-cell {
        input {
          border: none;
          background: rgba(250, 251, 252, 1);
          background-image: url('../../assets/faultrReporting/no-check-arrow.png');
          background-position: 98% 50%;
          background-repeat: no-repeat;
          background-size: 18px;
        }
        .van-field__control:disabled {
          color: rgba(213, 214, 221, 1);
          -webkit-text-fill-color: rgba(213, 214, 221, 1);
        }
      }
    }

    ::v-deep .van-cell {
      flex-direction: column;
      &::after {
        border-bottom: none;
      }
      .van-field__label {
        font-size: 16px;
        font-weight: 600;
        color: rgba(0, 0, 0, 1);
        &::before {
          content: '*';
          position: absolute;
          top: 23%;
          transform: translateY(-50%);
          color: rgba(255, 57, 27, 1);
        }
        span {
          margin-left: 10px;
        }
      }

      input {
        width: 100%;
        height: 48px;
        opacity: 1;
        border-radius: 6px;
        background: #f4f7f9;
        border: 1px solid #eaeeee;
        margin-top: 12px;
        font-size: 16px;
        font-weight: 400;
        padding-left: 12px;
        background-image: url('../../assets/faultrReporting/arrow-down.png');
        background-position: 98% 50%;
        background-repeat: no-repeat;
        background-size: 18px;
      }
      textarea {
        width: 100%;
        height: 96px !important;
        opacity: 1;
        border-radius: 6px;
        background: #f4f7f9;
        border: 1px solid #eaeeee;
        padding: 13px 12px;
        margin-top: 12px;
        font-size: 16px;
      }
    }
    ::v-deep .van-cell--clickable:active {
      background-color: #fff;
    }

    .textDes {
      ::v-deep .van-cell {
        .van-field__label {
          &::before {
            content: '*';
            position: absolute;
            top: 15%;
          }
        }
      }
    }
    .upload {
      width: 100%;
      background: #ffffff;
      padding: 20px 16px;

      .top {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        color: rgba(0, 0, 0, 1);
        position: relative;
        &.required {
          padding-left: 10px;
          &::before {
            content: '*';
            position: absolute;
            left: 0px;
            top: 15%;
            color: rgb(255, 57, 27);
          }
        }

        > span {
          &:nth-child(1) {
            font-size: 16px;
            font-weight: 600;
          }
          &:nth-child(2) {
            margin-left: 6px;
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
      .upload-image {
        width: 86px;
        height: 86px;
      }
      .pic {
        .pic-upload {
          ::v-deep .van-uploader__preview {
            margin: 0 15px 15px 0;
          }
          ::v-deep .van-uploader__preview-delete {
            background-image: url('https://cybertron-public.neolix.net/NeolixData/Default/frontend/Frontend/zsd/item_delete.png');
            background-repeat: no-repeat;
            background-size: 22px;
            background-color: transparent;
            width: 22px;
            height: 22px;
            border-radius: 0;
            top: -9px;
            right: -7px;
          }
          ::v-deep .van-icon-cross:before {
            content: none;
          }
        }

        .deleteUploaded {
          position: absolute;
          left: 0;
          top: 0;
          img {
            width: 22px;
            height: 22px;
          }
        }
      }
    }
    ::v-deep .van-popup {
      border-radius: 16px 16px 0px 0px;
      .van-picker__toolbar {
        height: 55px;
        .van-picker__cancel {
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #000000;
          letter-spacing: 0;
          font-weight: 400;
        }
        .van-picker__confirm {
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #436eff;
          letter-spacing: 0;
          font-weight: 400;
        }
        .van-picker-column__item {
          padding: 0 10px;
        }
      }
    }
  }
  .bottomBtn {
    background: #fff;
    position: fixed;
    bottom: 0;
    padding: 24px 24px;
    box-sizing: border-box;
    width: 100%;

    &.submitBtn {
      > div {
        height: 46px;
        line-height: 46px;
        background: rgba(67, 110, 255, 1);
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: white;
        border-radius: 23px;
        &.disabled {
          background: rgba(203, 213, 251, 1);
        }
      }
    }

    &.editBtn {
      > div {
        height: 46px;
        line-height: 46px;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: rgba(67, 110, 255, 1);
        border-radius: 23px;
        border: 2px solid rgba(67, 110, 255, 1);
        box-sizing: border-box;
      }
    }
  }
}

.uploadTrigger {
  margin-top: 0px;
  width: 86px;
  height: 86px;
  img {
    width: 86px;
    height: 86px;
    pointer-events: all;
  }
}

::v-deep .van-uploader__input-wrapper {
  pointer-events: none;
}
</style>
