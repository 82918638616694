import qs from "qs";
import env from "../../env";
import { Toast } from "vant";

const baseUrl = `${env.baseURL3}`;

function request(url, options = {}) {
  const {
    method = "GET",
    data = null,
    headers = {},
    timeout = 60000,
    isUrl = true
  } = options;
  const fetchOptions = { method, headers };
  if (data) {
    if (method === "GET") {
      url += `?${qs.stringify(data)}`;
    } else {
      if(isUrl){
        fetchOptions.body = JSON.stringify(data)
        headers["Content-Type"] = "application/json; charset=utf-8";
      }else{
        fetchOptions.body = data
      }
    }
  }
  // 设置超时时间
  const controller = new AbortController();
  const signal = controller.signal;
  const timerId = setTimeout(() => controller.abort(), timeout);
  return fetch(isUrl ? `${baseUrl}${url}` : url, {
    ...fetchOptions,
    signal,
  })
    .catch((e) => {
      Toast({
        message: "请求失败",
        className: "response-toast",
        forbidClick: true,
      });
    })
    .then((response) => {
      clearTimeout(timerId);
      if (response.ok) {
        if (response.status === 204) return
        return response.json();
      }
      Toast({
        message: `请求失败${response.status}`,
        className: "response-toast",
        forbidClick: true,
      });
    })
    .then((result) => {
        // errcode 0（正常），-1（错误）
      if (result === undefined) return
      if (result.errmsg === 'success' && result.errcode === 0) {
        return result.data;
      } else {
        Toast({
          message: `${result.errmsg}`,
          className: "response-toast",
          forbidClick: true,
        });
        return Promise.reject()
      }
    });
}
export default request;
