/** * 快捷选择年月日时分秒 * * @cancel 取消 * @confirm 确定 */
<template>
  <div>
    <!-- 弹出层 -->
    <van-popup
      class="time-box"
      get-container="body"
      v-model="isPicker"
      position="bottom"
      @close="confirmOn"
      style="border-radius: 16px 16px 0px 0px"
    >
      <!-- 时间选择 -->
      <van-picker
        ref="picker"
        show-toolbar
        title="请选择时间"
        :columns="columns"
        @change="onChange"
        @cancel="cancelOn"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>
<script>
export default {
  name: "popup",
  props: ["showPicker", "values"],
  data() {
    return {
      isPicker: false, //是否显示弹出层
      columns: [], //所有时间
      Mdays: "", //弹窗关闭时月份所在值
      Dindex: null,
    };
  },
  watch: {
    isPicker(val) {
      !val && this.$emit("cancel");
      this.columns = [];
      this.getcolumns();
    },
    showPicker(val) {
      this.isPicker = val;
    },
    values(val) {
      if (val == "") {
        this.Mdays = "";
        this.Dindex = null;
      }
    },
  },
  methods: {
    getCountDays(year, month) {
      //获取某年某月多少天
      var day = new Date(year, month, 0);
      return day.getDate();
    },
    getcolumns() {
      var strtime = this.values;
      var date = new Date(strtime.replace(/-/g, "/"));
      var vmoduletime = date.getTime();
      if (this.values != "") {
        var vmoduledate = new Date(vmoduletime);
      } else {
        var vmoduledate = new Date(); //没有传入时间则默认当前时刻
      }
      var Y = vmoduledate.getFullYear();
      var M = vmoduledate.getMonth();
      var D = vmoduledate.getDate();
      var h = vmoduledate.getHours();
      var m = vmoduledate.getMinutes();
      var s = vmoduledate.getSeconds();
      var year = {}; //获取前后十年数组
      year.values = [];
      var Currentday = new Date().getFullYear();
      for (var i = Currentday - 10; i < Currentday + 10; i++) {
        year.values.push(i + "年");
      }
      year.defaultIndex = year.values.indexOf(Y + "年"); //设置默认选项当前年
      this.columns.push(year);
      var month = {}; //获取12月数组
      month.defaultIndex = M;
      month.values = Object.keys(Array.apply(null, { length: 13 })).map(
        function (item) {
          if (+item + 1 <= 10) {
            return "0" + item + "月";
          } else if (+item + 1 == 11) {
            return +item + "月";
          } else {
            return (+item + 0).toString() + "月";
          }
        }
      );
      month.values.splice(0, 1);
      this.columns.push(month);
      //获取当月的天数
      var days = this.getCountDays(Y, this.Mdays == "" ? M + 1 : this.Mdays);
      var day = {}; //创建当月天数数组
      day.defaultIndex = this.Dindex == null ? D - 1 : this.Dindex;
      day.values = Object.keys(Array.apply(null, { length: days + 1 })).map(
        function (item) {
          if (+item + 1 <= 10) {
            return "0" + item + "日";
          } else if (+item + 1 == 11) {
            return +item + "日";
          } else {
            return (+item + 0).toString() + "日";
          }
        }
      );
      day.values.splice(0, 1);
      this.columns.push(day);
      var hour = {}; //创建小时数组
      hour.defaultIndex = h;
      hour.values = Object.keys(Array.apply(null, { length: 24 })).map(
        function (item) {
          if (+item + 1 <= 10) {
            return "0" + item + "时";
          } else if (+item + 1 == 11) {
            return +item + "时";
          } else {
            return (+item + 0).toString() + "时";
          }
        }
      );
      this.columns.push(hour);
      var mi = {}; //创建分钟数组
      mi.defaultIndex = m;
      mi.values = Object.keys(Array.apply(null, { length: 60 })).map(function (
        item
      ) {
        if (+item + 1 <= 10) {
          return "0" + item + "分";
        } else if (+item + 1 == 11) {
          return +item + "分";
        } else {
          return (+item + 0).toString() + "分";
        }
      });
      this.columns.push(mi);
      var ss = {}; //创建秒数数组
      ss.defaultIndex = s;
      ss.values = Object.keys(Array.apply(null, { length: 60 })).map(function (
        item
      ) {
        if (+item + 1 <= 10) {
          return "0" + item + "秒";
        } else if (+item + 1 == 11) {
          return +item + "秒";
        } else {
          return (+item + 0).toString() + "秒";
        }
      });
      this.columns.push(ss);
    },
    onChange(values, a) {
      //a为所有列备选项值的数组
      var days = this.getCountDays(a[0].substr(0, 4), a[1].substr(0, 2));
      var newdays = {};
      newdays.values = Object.keys(Array.apply(null, { length: days + 1 })).map(
        function (item) {
          if (+item + 1 <= 10) {
            return "0" + item + "日";
          } else if (+item + 1 == 11) {
            return +item + "日";
          } else {
            return (+item + 0).toString() + "日";
          }
        }
      );
      newdays.values.splice(0, 1);
      this.$refs.picker.setColumnValues(2, newdays.values); //设置第三列的值
      this.$refs.picker.setColumnIndex(2, a[2].substr(0, 2) - 1); //设置第三列索引
    },
    // 关闭弹框
    confirmOn() {
      var currentM = this.$refs.picker.getColumnValue(1);
      this.Mdays = currentM.substr(0, 2);
      this.Dindex = this.$refs.picker.getColumnIndex(2);
    },
    //时间选择器关闭
    cancelOn() {
      this.$emit("cancel");
    },
    // 时间选择器确定
    onConfirm(val, index) {
      var endval =
        val[0].substr(0, 4) +
        "-" +
        val[1].substr(0, 2) +
        "-" +
        val[2].substr(0, 2) +
        " " +
        val[3].substr(0, 2) +
        ":" +
        val[4].substr(0, 2) +
        ":" +
        val[5].substr(0, 2);
      this.$emit("changeValue", endval);
      this.$emit("confirm", endval);
    },
  },
};
</script>

<style scoped lang="scss">
.time-box {
  ::v-deep .van-picker__toolbar {
    height: 55px;
    .van-picker__cancel {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #000000;
      letter-spacing: 0;
      font-weight: 400;
    }
    .van-picker__confirm {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #436eff;
      letter-spacing: 0;
      font-weight: 400;
    }
    .van-picker-column__item {
      padding: 0 10px;
    }
  }
}
</style>
